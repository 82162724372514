@import "../../../styles/_chunk";

$pinata-btn-core: #ffde42;
$pinata-btn-dark: darken($pinata-btn-core, 8%);
$pinata-btn-border: #f9b33b;
$pinata-btn-text: #37300b;
$vip-btn-core: #0d0d0d;
$vip-btn-dark: darken($vip-btn-core, 8%);
$vip-btn-text: #fff;

.MysteryReveal {
  margin-bottom: 0.5rem;

  @include media-breakpoint-down(xs) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  canvas.pinataCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .MysteryReveal__content {
    background-color: $card-background-dark;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 550px;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    @include media-breakpoint-down(xs) {
      max-width: 360px;
    }

    @include media-breakpoint-up(md) {
      height: 480px;
    }

    @include media-breakpoint-up(lg) {
      // height: 380px;
    }

    @include media-breakpoint-up(xl) {
      height: 500px;
    }

    @include media-breakpoint-up(xxl) {
      height: 550px;
    }

    .MysteryReveal__content__intro {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 1rem;

      @include media-breakpoint-up(sm) {
        padding: 1.5rem;
      }
    }

    .MysteryReveal__content__intro__logo--vip {
      width: 260px;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(sm) {
        width: 340px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      @include media-breakpoint-up(xxl) {
        width: 440px;
      }
    }

    .MysteryReveal__content__intro__logo--fiesta {
      width: 260px;
      margin-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        width: 340px;
      }

      @include media-breakpoint-up(lg) {
        width: 320px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      @include media-breakpoint-up(xxl) {
        width: 460px;
      }
    }

    .MysteryReveal__content__intro__logo--scratchcard {
      width: 260px;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(sm) {
        width: 340px;
      }

      @include media-breakpoint-up(lg) {
        width: 320px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      @include media-breakpoint-up(xxl) {
        width: 420px;
      }
    }

    .MysteryReveal__content__intro__logo--wheel {
      width: 260px;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(sm) {
        width: 340px;
      }

      @include media-breakpoint-up(lg) {
        width: 320px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      @include media-breakpoint-up(xxl) {
        width: 460px;
      }
    }

    .MysteryReveal__content__intro__promoText {
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      color: $white;

      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 2.5rem;
        font-size: 24px;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 5rem;
        font-size: 26px;
      }
    }

    .MysteryReveal__content__intro__clickText {
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: $white;

      @include media-breakpoint-up(xl) {
        font-size: 17px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 18px;
      }
    }

    .MysteryReveal__revealButton {
      background-color: $primary-core;
      text-transform: uppercase;
      color: $text-black;
      font-weight: 900;
      border: 0;
      min-height: 44px;
      min-width: 200px;
      padding: 0 1.5rem;
      font-size: 18px;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: $primary-dark;
      }

      &--vip {
        background-color: $vip-btn-core;
        color: $vip-btn-text;
        box-shadow: 8px 4px 16px 8px rgba($black, 0.12);

        &:hover {
          background-color: $vip-btn-dark;
        }
      }

      &--fiesta {
        background-color: $pinata-btn-core;
        color: $pinata-btn-text;
        border-bottom: 5px solid $pinata-btn-border;
        box-shadow: 8px 4px 16px 8px rgba($black, 0.12);

        &:hover {
          background-color: $pinata-btn-dark;
        }
      }
    }

    .MysteryReveal__skipButton {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      margin-top: 1rem;
      text-decoration: underline;
      font-size: 15px;
      color: $white;
    }

    .MysteryReveal__content__intro__smallPrint {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      margin-top: 1rem;
    }

    .MysteryReveal__skipModal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      color: $text-black;
      animation: fadeIn 2s;

      .MysteryReveal__skipModal__content {
        height: 50%;
        width: 50%;
        background-color: $grey-1;
        box-shadow: map-get($light-theme, card-box-shadow);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1.5rem;
        position: relative;

        .MysteryReveal__skipModal__content__title {
          @extend .h4;

          font-weight: 700;
          margin-bottom: 0.25rem;
          text-align: center;
        }

        .MysteryReveal__skipModal__content__copy {
          margin-bottom: 1rem;
          font-weight: 400;
        }

        .MysteryReveal__skipModal__content__buttons {
          .MysteryReveal__skipModal__content__button {
            margin: 0 0.5rem;
            border-radius: $border-radius-sm;
            padding: 0.25rem 1rem;
            border: 1px solid $primary-core;
            background-color: $primary-core;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.15s ease-in-out;

            &:hover {
              background-color: $primary-dark;
              border-color: $primary-dark;
            }
          }
        }

        .MysteryReveal__skipModal__exitButton {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 24px;
          width: 40px;
          height: 40px;
          border: 0;
          background-color: transparent;

          svg {
            color: $grey-4;
          }
        }
      }
    }

    .MysteryReveal__content__testPannel {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      animation: fadeIn 5s;

      .MysteryReveal__content__testPannel__promoText {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
        color: $white;

        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }

        @include media-breakpoint-up(xl) {
          padding: 0 2.5rem;
          font-size: 24px;
        }

        @include media-breakpoint-up(xxl) {
          padding: 0 5rem;
          font-size: 26px;
        }
      }
    }

    .MysteryReveal__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .MysteryReveal__videoBackground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }
}
