@import "../../../styles/_chunk";

.RevealPrizeStep {
  animation: fadeIn 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.75);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 2rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 3rem;
  }

  @include media-breakpoint-up(xxl) {
    padding: 5rem;
  }

  .RevealPrizeStep__title {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 18px;
    color: $white;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 24px;
    }
  }

  .RevealPrizeStep__item {
    font-weight: 700;
    font-size: 16px;
    color: $white;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 20px;
    }
  }

  .RevealPrizeStep__itemCard {
    width: 263px;
  }
}
