@import "../../../styles/_chunk";

$pinata-btn-core: #ffde42;
$pinata-btn-dark: darken($pinata-btn-core, 8%);
$pinata-btn-border: #f9b33b;
$pinata-btn-text: #37300b;
$vip-btn-core: #0d0d0d;
$vip-btn-dark: darken($vip-btn-core, 8%);
$vip-btn-text: #fff;

.MysteryRevealGames {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MysteryRevealGames__topText {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font-size: 22px;
      padding: 0 1rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 26px;
    }
  }

  .MysteryRevealGames__gamesContainer {
    width: 100%;
    animation: fadeIn 5s;

    .MysteryRevealGames__gamesGrid {
      margin-bottom: 2.5rem;
      display: flex;
      justify-content: center;

      .MysteryRevealGames__gamesCol {
        width: 263px;
        padding: 1.5rem 1rem 0 1rem;
      }
    }

    .MysteryRevealGames__gamesSlider {
      margin-bottom: 2.5rem;

      .MysteryRevealGames__gamesSlider__arrows {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &.btn-left {
          left: 0;
          padding-left: 1rem;
        }

        &.btn-right {
          right: 0;
          padding-right: 1rem;
        }

        button.slick-arrow {
          height: 40px;
          width: 40px;
          background: transparent;
          border: 1px solid $white;
          color: $white;
          border-radius: 50%;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }

      .slick-slide {
        padding: 1.5rem 1rem;
        opacity: 0.5;
        transition: all 0.5s ease-in-out;

        &.slick-current {
          opacity: 1;
        }
      }

      ul.slick-dots {
        margin-top: -0.5rem;

        li {
          padding-top: 0;
          padding-bottom: 0;

          button {
            background-color: transparent;
            width: 16px;
            height: 16px;
            border: 2px solid $white;
            margin: 0 4px;
          }

          &.slick-active {
            button {
              background-color: $primary-core !important;
              border-color: $primary-core !important;
            }
          }
        }
      }

      &--vip {
        ul.slick-dots {
          li.slick-active {
            button {
              background-color: $vip-btn-core !important;
              border-color: $vip-btn-core !important;
            }
          }
        }
      }

      &--fiesta {
        ul.slick-dots {
          li.slick-active {
            button {
              background-color: $pinata-btn-core !important;
              border-color: $pinata-btn-core !important;
            }
          }
        }
      }
    }
  }

  .MysteryRevealGames__soundButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: $white;
    opacity: 0.5;
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 0 !important;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }
}
