@import "../../../../styles/_chunk";

.WheelReveal {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  .WheelReveal__wheelArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: -0.25rem;

    .WheelReveal__wheelArrowContainer__arrow {
      position: absolute;
      top: 0;
      z-index: 1;

      img {
        width: 44px;
      }
    }

    .WheelReveal__wheelArrowContainer__wheel {
      width: 360px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media only screen and (max-width: 320px) {
        width: 280px;
      }

      @include media-breakpoint-up(sm) {
        width: 440px;
      }

      @include media-breakpoint-up(xl) {
        width: 460px;
      }

      @include media-breakpoint-up(xxl) {
        width: 500px;
      }

      img {
        width: 100%;
      }
    }
  }
}
