@import "../../../../styles/_chunk";

.PinataReveal {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .PinataReveal__pinataContainer {
    animation: sway 2.4s infinite;
    animation-timing-function: ease-in-out;
    transform-origin: top;
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    display: flex;
    flex-direction: column;
    align-items: center;

    .PinataReveal__rope {
      height: 240px;
      width: 4px;
      background-color: #3a4e1b;
      content: "";
      display: block;
      margin-bottom: -180px;
      box-shadow: 0 2px 8px rgba($black, 0.12);

      @include media-breakpoint-up(sm) {
        height: 260px;
        margin-bottom: -200px;
      }

      @include media-breakpoint-up(md) {
        height: 300px;
        margin-bottom: -280px;
      }

      @include media-breakpoint-up(xxl) {
        height: 340px;
        margin-bottom: -320px;
      }
    }

    .PinataReveal__pinata {
      background-color: transparent;
      border: 0;
      padding: 0;
      width: 300px;
      margin-left: 20px;

      @include media-breakpoint-up(sm) {
        width: 320px;
      }

      @include media-breakpoint-up(md) {
        width: 340px;
      }

      @include media-breakpoint-up(xxl) {
        width: 380px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .PinataReveal__pinata__all {
        position: relative;
        height: 320px;

        @include media-breakpoint-up(sm) {
          height: 340px;
        }

        @include media-breakpoint-up(md) {
          height: 362px;
        }

        @include media-breakpoint-up(xxl) {
          height: 405px;
        }
      }

      .PinataReveal__pinata__top,
      .PinataReveal__pinata__bottom {
        position: absolute;
        top: 0;
      }

      &--open {
        animation: pinata-bottom 1s forwards ease-in-out;

        .PinataReveal__pinata__top {
          animation: pinata-top 1s forwards ease-in-out;
        }
      }

      &--one {
        animation: pinata-bottom-whack-one 0.5s forwards ease-in-out;

        .PinataReveal__pinata__top {
          animation: pinata-top-whack-one 0.5s forwards ease-in-out;
        }
      }

      &--two {
        animation: pinata-bottom-whack-two 0.5s forwards ease-in-out;

        .PinataReveal__pinata__top {
          animation: pinata-top-whack-two 0.5s forwards ease-in-out;
        }
      }
    }
  }

  .PinataReveal__hitText {
    margin-top: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: $white;
    padding: 0 2rem;

    @include media-breakpoint-up(sm) {
      font-size: 22px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 26px;
    }
  }
}

@keyframes sway {
  0% {
    transform: rotate(16deg);
  }

  50% {
    transform: rotate(-16deg);
  }

  100% {
    transform: rotate(16deg);
  }
}
@keyframes pinata-top {
  0%,
  25% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  50% {
    transform: translate3d(-20%, -60%, 0) rotate(-25deg);
  }

  100% {
    transform: translate3d(-40%, 120%, 0) rotate(-70deg);
  }
}
@keyframes pinata-bottom {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  25% {
    transform: translate3d(0%, 20%, 0) rotate(10deg);
  }

  50% {
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }

  70% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes pinata-top-whack-one {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  50% {
    transform: translate3d(-2%, -4%, 0) rotate(-5deg);
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes pinata-bottom-whack-one {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  50% {
    transform: translate3d(0%, -2%, 0) rotate(5deg);
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes pinata-top-whack-two {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  50% {
    transform: translate3d(-2%, -4%, 0) rotate(5deg);
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes pinata-bottom-whack-two {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  50% {
    transform: translate3d(0%, -2%, 0) rotate(-5deg);
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
