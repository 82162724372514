@import "../../../styles/_chunk";

.MysteryRevealCard {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  width: 100%;
  height: 240px;

  @include media-breakpoint-up(sm) {
    height: 260px;
  }

  @include media-breakpoint-up(md) {
    height: 242px;
  }

  @include media-breakpoint-up(lg) {
    height: 248px;
  }

  @include media-breakpoint-up(xl) {
    height: 244px;
  }

  @include media-breakpoint-up(xxl) {
    height: 260px;
  }

  .MysteryRevealCard__content {
    box-shadow: 8px 4px 16px 8px rgba($black, 0.12);
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }

  .MysteryRevealCard__front,
  .MysteryRevealCard__back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
  }

  .MysteryRevealCard__front {
    transform: rotateY(180deg);
  }

  .MysteryRevealCard__back {
    background: radial-gradient(circle, $grey-14 0%, $grey-16 100%);
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    font-weight: 900;
  }

  .MysteryRevealCard__body {
    padding: 1rem 0.75rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    text-align: left;
  }

  .MysteryRevealCard__grade {
    font-size: 11px;
    color: $text-muted;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .MysteryRevealCard__title {
    color: $text-black;
    white-space: normal;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 46px;
  }

  .MysteryRevealCard__icons {
    display: flex;
    align-items: center;
  }

  .MysteryRevealCard__drm {
    margin-right: 1rem;
  }

  .MysteryRevealCard__os {
    font-size: 14px;
    color: map-get($light-theme, card-icons-color);
  }

  &--flipped {
    .MysteryRevealCard__content {
      transform: rotateY(180deg);
    }
  }
}
