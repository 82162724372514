@import "../../../../styles/_chunk";

.ScratchCardReveal {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ScratchCardReveal__card {
    position: relative;
    width: 95%;
    background-color: $white;
    padding: 1.5rem 1rem;
    box-shadow: 8px 4px 16px 8px rgba($black, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('https://cdn.fanatical.com/production/assets/mystery/scratchcard/2023/red-hot-sale-scratchcard-card-bg.jpg');
    background-position: right;
    background-size: cover;

    @include media-breakpoint-up(sm) {
      width: 380px;
      min-height: 380px;
    }

    @include media-breakpoint-up(lg) {
      width: 500px;
      min-height: 420px;
    }

    @include media-breakpoint-up(xl) {
      width: 680px;
      min-height: 320px;
      padding: 1.5rem;
      flex-direction: row;
    }

    @include media-breakpoint-up(xxl) {
      width: 800px;
      min-height: 400px;
      padding: 2rem;
    }

    .ScratchCardReveal__card__leftContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-up(xl) {
        padding-right: 1.5rem;
      }

      @include media-breakpoint-up(xxl) {
        padding-right: 2rem;
      }
    }

    .ScratchCardReveal__card__rightContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .ScratchCardReveal__card__scratchLogo {
      margin-bottom: 1rem;
      width: 200px;

      @include media-breakpoint-up(sm) {
        width: 240px;
      }

      @include media-breakpoint-up(xl) {
        width: 260px;
      }

      @include media-breakpoint-up(xxl) {
        width: 300px;
        margin-bottom: 1.5rem;
      }
    }

    .ScratchCardReveal__card__text {
      margin-bottom: 1rem;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      text-align: center;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        font-size: 16px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 18px;
      }
    }
  }

  .ScratchCardReveal__card__scratchContainer {
    width: 300px;
    height: 169px;
    position: relative;
    background-color: $grey-2;
    color: $text-black;
    font-weight: 700;
    z-index: 2;
    flex-shrink: 0;
    box-shadow: 8px 4px 16px 8px rgba($black, 0.12);

    @include media-breakpoint-up(xxl) {
      width: 380px;
      height: 214px;
    }

    .ScratchCardReveal__card__scratchContainer__canvas {
      cursor: pointer;
      width: 300px;
      height: 169px;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;
      z-index: 20;
      border: 4px solid $white;

      @include media-breakpoint-up(xxl) {
        width: 380px;
        height: 214px;
      }
    }

    .ScratchCardReveal__card__scratchContainer__cover {
      cursor: pointer;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ScratchCardReveal__keyButton {
    text-transform: uppercase;
    color: $white;
    font-weight: 400;
    border: 0;
    font-size: 15px;
    transition: all 0.15s ease-in-out;
    margin-top: 1rem;
    text-decoration: underline;
    background: transparent;
  }

  .ScratchCardReveal__testRevealText {
    color: $white;
    font-weight: 400;
    font-size: 15px;
    margin-top: 1rem;
    text-align: center;
  }
}
